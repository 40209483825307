import HeaderNav from '../partials/headerNav';
import Address from '../partials/adress';
import './style.css';
// eslint-disable-next-line
import { FiAlignJustify } from 'react-icons/fi';
// eslint-disable-next-line
import {  useState} from 'react';
// eslint-disable-next-line
import useMediaQuery from '../../hooks/useMediaQuery';
import { useContext, useRef } from 'react';
import {Context} from '../../Wrapper'




const mobile = "(max-width: 500px)"
// eslint-disable-next-line
const pad = "(max-width: 850px)";


const Header = () => {
    // eslint-disable-next-line
    const [isToggle, setIsToggle] = useState(false);
    const isMobile = useMediaQuery(mobile)
    
    const context = useContext(Context)

    let showNav = isToggle ? "toggler": "";

   const adr = useRef(null)
   

    return (
        <header>
            <div className="before_nav">
                <div className="before_nav-flexer">
                    <Address isMobile={isMobile} toggler={isToggle} adr={adr}/>
                   <div className="lang-toggler">
                        <select value={context.local} onChange={context.selectLanguage} className="langSelector">
                            <option value="es">
                               Spanish
                            </option>
                            <option value="en">
                                English
                            </option>
                            <option value="fr">    
                                French
                            </option>
                            <option value="it">    
                                Italian
                            </option>
                            <option value="sq">    
                                Albanian
                            </option>
                            <option value="el">    
                                Greek
                            </option>
                        </select>
                    </div> 
                </div>
            </div>
            <nav>
                <a href="/">
                    <img src="./logo1.png" alt="solucion urgente - préstamos rapido y seguro" className="logo" />
                </a>
                <HeaderNav showNav={showNav} isMobile={isMobile} adr={adr} isToggle={isToggle} setIsToggle={setIsToggle}/>
                <FiAlignJustify className="menu" onClick={() => setIsToggle(!isToggle)}/>
            </nav>
        </header>
    )
}

export default Header;