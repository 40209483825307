import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import './index.css';
import {BrowserRouter} from 'react-router-dom'

// eslint-disable-next-line
import  Wrapper from './Wrapper'







const root = createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Wrapper>
              <App/>
            </Wrapper>
        </BrowserRouter>
    </React.StrictMode>
)