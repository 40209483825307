import './style.css'

const Hero = ({img, title, children}) => {
    return (
        <section className="hero">
            {img && <div className="img_container"> <img src={img} alt="banner" className="img"/></div>}
            <div className="banner_text">
                <p>#Solucion Urgente</p>
                <p className="strong">{title}</p>
                {children}
            </div>
        </section>
    )
}

export default Hero;