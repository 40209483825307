import {FiMail, FiMapPin } from 'react-icons/fi';
import './style.css'


const Address = ({direction = 'row', align='center', isMobile, toggler, adr}) => {
    const mobileStyle = {
        flexDirection: "column",
        position: "absolute",
        top: "272%",
        left: 0,
        zIndex: "100",
        backgroundColor: "var(--primary-bg)",
        width: "100%",
        padding: "20px 0",
        transition: "all 0.4s ease-in",
       
    }

    const mobileClass = toggler ? "toggled " : "";
    const translater = isMobile ? "translater": ""

    const style = isMobile ? mobileStyle: {flexDirection: direction, alignItems: align}
    return (
        <address style={style} className={`${translater} ${mobileClass}`} ref={adr}>
            

            <div>
                <FiMail/>
                <span>contact@solucionurgente.es</span>
            </div>

            <div>
                <FiMapPin/>
                <span>Espagne, Tijuana 28003 Madrid</span>
            </div>
        </address>
    )
}



export default Address;