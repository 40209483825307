import { NavLink } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useRef } from "react";

import "./style.css";

const HeaderNav = ({ showNav, isMobile,adr, isToggle, setIsToggle }) => {
  const intl = useIntl();
  const acceuil = (
    <FormattedMessage id="navigation.li1" defaultMessage="Bienvenida" />
  );
  const formulaire = (
    <FormattedMessage
      id="navigation.li2"
      defaultMessage="Solicitud de préstamo"
    />
  );
  const terms = (
    <FormattedMessage
      id="navigation.li3"
      defaultMessage="Términos-y-Condiciones"
    />
  );
  const faq = (
    <FormattedMessage
      id="navigation.li4"
      defaultMessage="Preguntas-frecuentes"
    />
  );
  const contact = (
    <FormattedMessage id="navigation.li5" defaultMessage="Contactar" />
  );

  const nav = useRef(null);

  const handleChange =
    isMobile &&
    function () {
        
      nav.current.classList.remove('toggler');
      adr.current.classList.remove('toggled');
        setIsToggle(!isToggle);
      
    };

  return (
    <ul className={`header_nav ${showNav}`} ref={nav}>
      <NavLink exact activeClassName="active" to="/" onClick={handleChange}>
        <li>{intl.formatMessage({ id: acceuil.props.id })}</li>
      </NavLink>

      <NavLink
        activeClassName="active"
        to={
          "/solicitud-de-prestamo" || "/Loan-application" || "/Demande-de-prêt"
        }
        onClick={handleChange}
      >
        <li>
          {intl.formatMessage({ id: formulaire.props.id }).replace(/-/g, " ")}
        </li>
      </NavLink>

      <NavLink
        activeClassName="active"
        to={
          "/terminos-y-condiciones" ||
          "/Terms-and-conditions" ||
          "/Termes-et-conditions"
        }
        onClick={handleChange}
      >
        <li>{intl.formatMessage({ id: terms.props.id }).replace(/-/g, " ")}</li>
      </NavLink>

      <NavLink
        activeClassName="active"
        to={"/preguntas-frecuentes" || "/FAQ" || "/Questions-fréquentes"}
        onClick={handleChange}
      >
        <li>{intl.formatMessage({ id: faq.props.id }).replace(/-/g, " ")}</li>
      </NavLink>

      <NavLink
        activeClassName="active"
        to={"/contactar" || "/Contact" || "/Contact"}
        onClick={handleChange}
      >
        <li>
          {intl.formatMessage({ id: contact.props.id }).replace(/-/g, " ")}
        </li>
      </NavLink>
    </ul>
  );
};

export default HeaderNav;
