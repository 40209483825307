import './style.css';
import {FiArrowRight} from 'react-icons/fi'
import { Link } from 'react-router-dom';
import Hero from '../../components/partials/heroSection';
import Button from '../../components/partials/button';
import director from '../../assets/jean-beaufort.jpg'
import { FormattedMessage } from 'react-intl';

const defaultBannerText = "Ofrecemos préstamos personales, para automóviles, inmobiliarios y otros. Toma nota de nuestros términos y condiciones para poder beneficiarte de ellos."
const defaultConseilsPara = "¿Quieres hacer un préstamo rápido y urgente? No lo dudes más, cumple nuestras condiciones y podrás beneficiarte de un dinero que te será útil en tus proyectos o emergencias."
const defaultReconnaissanceText1 = "Cuando le prestas dinero a alguien, lo mejor es tener un recibo escrito que pueda justificar la deuda. Por ello es necesario mencionar algunos criterios para que este reconocimiento de deuda sea efectivo. Es el prestatario quien debe realizar manualmente este reconocimiento de deuda. Se especifica que esto es obligatorio a nivel municipal si el importe prestado es superior a 1.500 euros. El reconocimiento de deuda deberá estar fechado y firmado por ambas partes."
const defaultReconnaissanceText2 = "Debe estar redactado en tres (3) copias, una para el solicitante, otra para el prestamista y también otra para el registro en nuestra base de datos de Fast Money. Para evitar cualquier inconveniente que pueda surgir en materia de dinero, solemos dejar por escrito la suma contratada con nosotros por un tercero. Disponer de un certificado de deuda nos permite, por tanto, evitar acudir a los tribunales en caso de mala fe por parte del solicitante o del prestatario."
const defaultReconnaissanceText3 = "Al establecer un certificado de deuda, se deben especificar las condiciones de pago. Si no se menciona nada, se podrá solicitar el reembolso de la deuda previo envío de un requerimiento. Si aún así no se devuelve el dinero, es inevitable recurrir a los tribunales. Si la cantidad es inferior a 6.000 euros, este proceso es bastante sencillo, sin embargo, si está entre 6.001 euros y 10.000 euros, el tribunal superior procederá al decomiso. Si la cantidad es superior a 10.000 euros, estarás obligado en este momento a recurrir a un abogado porque el caso será conocido por el Tribunal Supremo."


const Terms = () => {
    return (
        <>
            <Hero 
                title={<FormattedMessage id="terms.banner.title" defaultMessage="Términos-y-Condiciones"/>}
            >
                <h1>
                    <FormattedMessage id="terms.banner.text" defaultMessage={defaultBannerText}/>
                </h1>
                <Link to="/Solicitud-de-préstamo">
                <Button>
                    <FormattedMessage id="btn1" defaultMessage="Solicitar un préstamo" />
                </Button>
                </Link>
            </Hero>
            <section className="conseils">
                <div className="conseils_content">
                    <h3>
                        <FormattedMessage id="terms.conseils.title" defaultMessage="Aquí están nuestros términos y condiciones." />
                    </h3>
                    <p>
                        <FormattedMessage id="terms.conseils.para" defaultMessage={defaultConseilsPara} />
                    </p>
                    <ol type="1">
                        <li>
                            <FormattedMessage id="terms.conseils.li1" defaultMessage="Para solicitar un préstamo es necesario estar en posesión de un documento de identidad válido."/> <br/>
                            <FormattedMessage 
                                id="terms.conseils.nb" 
                                defaultMessage="Notese bien: El permiso de conducir no puede considerarse como tal."
                                values = {
                                    {strong: (word) => <strong>{word}</strong>}
                                }
                            />
                        </li>

                        <li>
                            <FormattedMessage 
                                id="terms.conseils.li2" 
                                defaultMessage="Deberá proporcionarnos un comprobante de residencia fechado dentro de los últimos tres (3) meses."
                            />
                        </li>

                        <li>
                            <FormattedMessage 
                                id="terms.conseils.li3" 
                                defaultMessage="También necesitaremos sus tres últimos extractos bancarios o cualquier otro documento que justifique sus ingresos y beneficios."
                            />
                        </li>

                        <li>
                            <FormattedMessage 
                                id="terms.conseils.li4" 
                                defaultMessage="Deberás facilitarnos tu contrato de trabajo o cualquier otro documento que pueda servir como prueba de tu condición de empleado."
                            />
                        </li>

                        <li>
                            <FormattedMessage 
                                id="terms.conseils.li5" 
                                defaultMessage="También le pediremos que nos proporcione un comprobante de su solicitud de préstamo que detallará su proyecto. Puede ser por ejemplo una factura, un presupuesto o un pago de compra o venta."
                            />
                        </li>
                        <li>
                            <FormattedMessage 
                                id="terms.conseils.li6" 
                                defaultMessage="Si es director de una empresa, deberá proporcionarnos sus dos últimos formularios de impuestos. También agradeceremos la presentación de un balance provisional y las tarifas firmadas para los próximos años."
                            />
                        </li>

                        <li>
                            <FormattedMessage 
                                id="terms.conseils.li7" 
                                defaultMessage="Una vez redactado el contrato, se enviará a la oficina de Hacienda para su registro y declaración de impuestos. Es importante que presente esta declaración de impuestos para determinar la fecha del préstamo, el monto adeudado y el pago de las tarifas de solicitud para tener un documento que justifique el origen de los fondos."
                            />
                        </li>

                        <li>
                            <FormattedMessage 
                                id="terms.conseils.li8" 
                                defaultMessage="Sólo el importe del registro del préstamo a nivel fiscal será de su responsabilidad."
                            />
                        </li>
                        <li>
                            <FormattedMessage 
                                id="terms.conseils.li9" 
                                defaultMessage="Después del registro y declaración ante las autoridades fiscales, deberás recibir el desembolso del préstamo en tu cuenta en un plazo máximo de 24 horas. También recibirá una carta mencionando la solicitud de autorización de domiciliación bancaria mensual que deberá firmar y devolvernos."
                            />
                        </li>

                        <li>
                            <FormattedMessage 
                                id="terms.conseils.li10" 
                                defaultMessage="Si estas condiciones te convienen, haz tu solicitud e iniciaremos el trámite."
                            />
                        </li>
                    </ol>
                    <Link to="/Demande-de-prêt">
                    <Button>
                        <FormattedMessage id="btn1" defaultMessage="Solicitar un préstamo" />
                        <FiArrowRight className="btn-arrow"/>
                    </Button>
                    </Link>
                </div>
            </section>

            <section className="restrictions">
                <div className="restrictions_text">
                    <h4>
                        <FormattedMessage id="terms.restrictions.title1" defaultMessage="Restricciones legales de precios 2022/2023" />
                    </h4>
                    <h3>
                        <FormattedMessage id="terms.restrictions.title2" defaultMessage="Algunos ejemplos de tarifas que aplicamos." />
                    </h3>
                    <div className="restrictions_para">
                        <p>
                            <FormattedMessage id="terms.restrictions.text1" defaultMessage="Solución de emergencia para un préstamo al consumo superior a £5000: 2,44%" />
                        </p>
                        <p>
                            <FormattedMessage id="terms.restrictions.text2" defaultMessage="Por reserva de dinero o sobregiro: 2%" />
                        </p>
                        <p>
                            <FormattedMessage id="terms.restrictions.text3" defaultMessage="Para un préstamo amortizable de menos de £5000: 3,47%" />
                        </p>
                        <p>
                            <FormattedMessage id="terms.restrictions.text4" defaultMessage="Para un préstamo personal o hipotecario superior a £10.000: 2,5%" />
                        </p>
                    </div>
                    <ul className="restrictions_list">
                        <li>
                            <span>
                                <FormattedMessage id="terms.restrictions.list1" defaultMessage="El crédito al consumo" />
                            </span>
                            <span className="restrictions_taux">2%</span>
                        </li>

                        <li>
                            <span>
                                <FormattedMessage id="terms.restrictions.list2" defaultMessage="Reserva de efectivo" />
                            </span>
                            <span className="restrictions_taux">2%</span>
                        </li>

                        <li>
                            <span>
                                <FormattedMessage id="terms.restrictions.list3" defaultMessage="Préstamo depreciable" />
                            </span>
                            <span className="restrictions_taux">3%</span>
                        </li>
                    </ul>
                </div>
                <div className="restrictions_img">
                    <img src={director} alt="solucion urgente" />
                </div>
            </section>

            <section className="conseils">
                <div className="conseils_content para">
                    <h3>
                        <FormattedMessage id="terms.reconnaissance.title" defaultMessage="La cuestión del reconocimiento de la deuda." />
                    </h3>
                    <p>
                        <FormattedMessage id="terms.reconnaissance.text1" defaultMessage={defaultReconnaissanceText1} />
                    </p>
                    <p>
                        <FormattedMessage id="terms.reconnaissance.text2" defaultMessage={defaultReconnaissanceText2} />
                    </p>
                    <p>
                        <FormattedMessage id="terms.reconnaissance.text3" defaultMessage={defaultReconnaissanceText3} />
                    </p>
                    <Link to="/Demande-de-prêt">
                    <Button>
                        <FormattedMessage id="btn1" defaultMessage="Solicitar un préstamo" />
                        <FiArrowRight className="btn-arrow"/>
                    </Button>
                    </Link>
                </div>
            </section>
        </>
    )
}
export default Terms;