import './style.css';
import {useIntl} from 'react-intl'


const FormInput = ({label, children}) => {
    const intl = useIntl()
   
    return (
        <div className="input-group">
            <label htmlFor={intl.formatMessage({id: label.props.id})}>
                {intl.formatMessage({id: label.props.id}).replace(/-/g, " ")}
            </label>
            {children}
        </div>
    )
}

export default FormInput;