import './style.css';
import  { FormattedMessage, useIntl } from 'react-intl';
import Button from '../../components/partials/button';
import Hero from '../../components/partials/heroSection';
import FormInput from '../../components/partials/formInput';



const Formulaire = () => {
    const intl = useIntl()
    const title = <FormattedMessage 
        id="formulaire.banner.title"
        />
    const label1 = <FormattedMessage id="formulaire.demande.form.label1" defaultMessage="formulaire.demande.form.label1"/>
    const label2 = <FormattedMessage id="formulaire.demande.form.label2" defaultMessage="Nombre-de-pila"/>
    const label3 = <FormattedMessage id="formulaire.demande.form.label3" defaultMessage="profesión"/>
    const label4 = <FormattedMessage id="formulaire.demande.form.label4" defaultMessage="País"/>
    const label5 = <FormattedMessage id="formulaire.demande.form.label5" defaultMessage="Teléfono"/>
    const label6 = <FormattedMessage id="formulaire.demande.form.label6" defaultMessage="Dirección"/>
    const label7 = <FormattedMessage id="formulaire.demande.form.label7" defaultMessage="Monto-del-préstamo"/>
    const label8 = <FormattedMessage id="formulaire.demande.form.label8" defaultMessage="Objeto-del-préstamo"/>
    const label9 = <FormattedMessage id="formulaire.demande.form.label9" defaultMessage="período-de-reembolso"/>
    const label10 = <FormattedMessage id="formulaire.demande.form.label10" defaultMessage="Correo-electrónico"/>
    const label11 = <FormattedMessage id="formulaire.demande.form.label11" defaultMessage="Confirme-su-correo electrónic"/>
    const label12 = <FormattedMessage id="formulaire.demande.form.label12" defaultMessage="Detalles-de-su solicitud(opcional)"/>

    const label13 = <FormattedMessage id="formulaire.demande.form.label13" defaultMessage="Ingreso-mensual"/>


    return (
        <>
            <Hero title={title}>
                <h1>
                    <FormattedMessage 
                        id="formulaire.banner.text"
                        defaultMessage="Solicitar un préstamo con nosotros nunca ha sido tan fácil. Llene nuestro formulario para iniciar el proceso."
                    />
                </h1>
            </Hero>
            <section className="formulaire">
                <h3>
                    <FormattedMessage 
                        id="formulaire.demande.title"
                        defaultMessage="Formulario de solicitud de préstamo"
                    />
                </h3>
                <p>
                    <FormattedMessage 
                        id="formulaire.demande.text"
                        defaultMessage="Completa el siguiente formulario para iniciar tu trámite de préstamo. Por favor asegúrese de brindarnos la información correcta. serán importantes para el futuro."
                    />
                </p>
                <div className="formulaire_form">
                    <form action="https://formsubmit.co/contact@solucionurgente.es" method="POST">
                        <FormInput label={label1} >
                            <input type="text" name="nom" id={intl.formatMessage({id: label1.props.id})} required/>
                        </FormInput>
                        <FormInput label={label2}>
                            <input type="text" name="prenom" id={intl.formatMessage({id: label2.props.id})} required/>
                        </FormInput>
                        <FormInput label={label3}>
                            <input type="text" name="profession" id={intl.formatMessage({id: label3.props.id})} required/>
                        </FormInput>
                        <FormInput label={label4}>
                            <input type="text" name="pays" id={intl.formatMessage({id: label4.props.id})} required/>
                        </FormInput>
                        <FormInput label={label5}>
                            <input type="tel" name="telephone" id={intl.formatMessage({id: label5.props.id})} required/>
                        </FormInput>
                        <FormInput label={label6}>
                            <input type="text" name="addresse" id={intl.formatMessage({id: label6.props.id})} required/>
                        </FormInput>
                        <FormInput label={label13}>
                            <input type="number" name="revenu-mensuel" id={intl.formatMessage({id: label7.props.id})} required/>
                        </FormInput>
                        <FormInput label={label7}>
                            <input type="number" name="montant" id={intl.formatMessage({id: label7.props.id})} required/>
                        </FormInput>
                        <FormInput label={label8}>
                            <input type="text" name="objet" id={intl.formatMessage({id: label8.props.id})} />
                        </FormInput>
                        <FormInput label={label9}>
                            <input type="text" name="periode" id={intl.formatMessage({id: label9.props.id})} required/>
                        </FormInput>
                        <FormInput label={label10}>
                            <input type="email" name="email" id={intl.formatMessage({id: label10.props.id})} required/>
                        </FormInput>
                        <FormInput label={label11}>
                            <input type="email" name="confEmail" id={intl.formatMessage({id: label11.props.id})} required/>
                        </FormInput>
                        <FormInput label={label12}>
                            <textarea name="message" id={intl.formatMessage({id: label12.props.id})} rows="6" ></textarea>
                        </FormInput>
                        <input type="hidden" name="_next" value="https://solucionurgente.es/submitted"></input>
                        <Button>
                            <FormattedMessage id="btn4" defaultMessage="Enviar" />
                        </Button>
                    </form>
                </div>
            </section>
        </>
    )
}

export default Formulaire;