import './style.css'
import {FiFacebook} from 'react-icons/fi'
import {Link} from 'react-router-dom'
import { FormattedMessage } from 'react-intl';

import NavItems from '../partials/navItems';
import Address from '../partials/adress';
import Button from '../partials/button';



const Footer = () => {
    return (
        <footer>
            <section className="footer_info">
                <div className="footer_nav">
                    <a href="/" className="footer_brand">
                        <img src="./logofoot.png" alt="solucion urgente" className="logo" />
                    </a>
                    <NavItems direction="column" align="flex-start"/>
                </div>
                <div className="adr">
                    <h4 className="title_h4">
                        <FormattedMessage 
                            id="footer.adr"
                            defaultMessage = "Addresse"
                        />
                    </h4>
                    <Address direction="column" align="flex-start"/>
                </div>
                <div className="footer_text">
                    <h4 className="title_h4">Solucion Urgente</h4>
                    <p>
                        <FormattedMessage
                            id="footer.para"
                            defaultMessage="Nous sommes un organisme spécialisé dans l'investissement et le prêt en ligne.
                            Notre objectif principal est de permettre aux clients les plus fiables d'obtenir un prêt bancaire."
                        />
                    </p>
                    <Link to="/Demande-de-prêt">
                    <Button>
                        <FormattedMessage 
                            id="btn1"
                            defaultMessage="Demander un prêt"
                        />
                    </Button>
                    </Link>
                    
                </div>
                
            </section>
            <div className="footer_crumb">
                <p>&copy;2020, SolucionUrgente corporate, 
                    <FormattedMessage 
                        id="footer.crumb"
                        defaultMessage="Tous droits réservés"
                    />
                </p>
                <div className="fbk">
                    <Link to="https.facebook.com">
                    <FiFacebook style={{color: "var(--secondary-bg)"}}/>
                    </Link>
                    
                </div>
               
            </div>
        </footer>
    )
}

export default Footer;