import {Routes, Route} from 'react-router-dom'






import Header from './components/header';
import Footer from './components/footer';
import Home from './pages/home';
import Formulaire from './pages/formulaire';
import Faq from './pages/faq';
import Terms from './pages/terms';
import Contact from './pages/contact';
import Thank from './pages/submitted/Thank'



const App = () => {
    
    
   

    return (
        <div className="container">
            <Header />
            <Routes>
                <Route exact path="/" element={<Home />}/>
                <Route exact path={"/solicitud-de-prestamo"} element={<Formulaire/>}/>
                <Route exact path={"/Loan-application"} element={<Formulaire/>}/>
                <Route exact path={"/Demande-de-prêt"} element={<Formulaire/>}/>
                <Route exact path={"/Domandadi-prestito"} element={<Formulaire/>}/>
                <Route exact path={"/Kredi-aplikim"} element={<Formulaire/>}/>
                <Route exact path={"/Αίτηση-δανείου"} element={<Formulaire/>}/>

                <Route exact path={"/terminos-y-Condiciones"} element={<Terms/>}/>
                <Route exact path={"/Terms-and-conditions"} element={<Terms/>}/>
                <Route exact path={"/Termes-et-conditions"} element={<Terms/>}/>
                <Route exact path={"/Termini-e-Condizioni"} element={<Terms/>}/>
                <Route exact path={"/Termat-dhe-Kushtet"} element={<Terms/>}/>
                <Route exact path={"/Όροι-και-προϋποθέσεις"} element={<Terms/>}/>

                <Route exact path={"/preguntas-frecuentes"} element={<Faq/>}/>
                <Route exact path={"/FAQ"} element={<Faq/>}/>
                <Route exact path={"/Questions-fréquentes"} element={<Faq/>}/>
                <Route exact path={"/Domande-frequenti"} element={<Faq/>}/>
                <Route exact path={"/FAPyetjet-e-bëra-shpesh"} element={<Faq/>}/>
                <Route exact path={"/συχνές-ερωτήσεις"} element={<Faq/>}/>

                <Route exact path={"/contactar"} element={<Contact/>}/>
                <Route exact path={"/Contact"} element={<Contact/>}/>
                <Route exact path={"/Contact"} element={<Contact/>}/>
                <Route exact path={"/Contatto"} element={<Contact/>}/>
                <Route exact path={"/Kontaktoni"} element={<Contact/>}/>
                <Route exact path={"/Επικοινωνία"} element={<Contact/>}/>

                <Route exact path={"/submitted"} element={<Thank/>}/>
            </Routes> 
            <Footer/>
        </div>
        
    )
}

export default App;