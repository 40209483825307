import  {useState, createContext} from 'react'
import { IntlProvider } from 'react-intl'
import French from './lang/frc.json';
import English from './lang/en.json'
import Espagnol from './lang/esp.json'
import Albanian from './lang/alb.json'
import Greek from './lang/grec.json'
import Italian from './lang/ita.json'

export const Context = createContext();
let locale = navigator.language;
locale = locale.split('-')[0]


let lang;

switch(locale) {
    case 'en':
        lang = English;
        break;
    case "fr":
        lang= French;
        break;
    case 'it':
        lang = Italian;
        break;
    case 'el':
        lang = Greek;
    break;
    case 'sq':
        lang = Albanian;
        break;
    default:
        lang = Espagnol
}


const Wrapper = (props) => {
    const [local, setLocal] = useState(locale);
    const [messages, setMessages] = useState(lang)

    console.log(local)

    const selectLanguage = (event) => {
        const newLocale = event.target.value;
        setLocal(newLocale);

        switch(newLocale){
            case 'en':
                setMessages(English);
                break;
            case 'fr':
                setMessages(French);
                break;
            case 'el':
                setMessages(Greek);
                break;
            case 'it':
                setMessages(Italian);
                break;
            case 'sq':
                setMessages(Albanian);
                break;
            default: 
                setMessages(Espagnol)
        }
    }

    return (
        <Context.Provider value= {{local, selectLanguage}}>
            <IntlProvider messages={messages} locale={local}>
                {props.children}
            </IntlProvider>
        </Context.Provider>
    )
}

export default Wrapper;