import {NavLink} from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl';
import './style.css';



const NavItems = ({direction = "row", align = "center"}) => {
    const intl = useIntl()
    const acceuil = <FormattedMessage id="navigation.li1" defaultMessage="Bienvenida" />
    const formulaire = <FormattedMessage id="navigation.li2" defaultMessage="Solicitud de préstamo" />
    const terms = <FormattedMessage id="navigation.li3" defaultMessage="Términos-y-Condiciones" />
    const faq = <FormattedMessage id="navigation.li4" defaultMessage="Preguntas-frecuentes" />
    const contact = <FormattedMessage id="navigation.li5" defaultMessage="Contactar" />

    

    
    return (
        <ul className="nav_items" style={{flexDirection: direction, alignItems: align}}>
           <NavLink exact activeClassName="active" to="/" >
                <li>
                    {intl.formatMessage({id: acceuil.props.id})}
                </li>
            </NavLink>


            <NavLink activeClassName="active" to={"/solicitud-de-prestamo" || "/Loan-application" || "/Demande-de-prêt"} >
                <li>
                    {intl.formatMessage({id: formulaire.props.id}).replace(/-/g, " ")}
                </li>
            </NavLink>


            <NavLink activeClassName="active" to={"/terminos-y-condiciones" || "/Terms-and-conditions" || "/Termes-et-conditions"} >
                <li>
                    {intl.formatMessage({id: terms.props.id}).replace(/-/g, " ")}
                </li>
            </NavLink>

            <NavLink activeClassName="active" to={"/preguntas-frecuentes" || "/FAQ" || "/Questions-fréquentes"} >
                <li>
                    {intl.formatMessage({id: faq.props.id}).replace(/-/g, " ")}
                </li>
            </NavLink>
            

            <NavLink activeClassName="active" to={"/contactar" || "/Contact" || "/Contact"} >
                <li>
                    {intl.formatMessage({id: contact.props.id}).replace(/-/g, " ")}
                </li>
            </NavLink>
        </ul>
    )
}

export default NavItems;