import { FormattedMessage } from 'react-intl';
import "./thank.css"

const Thank = () => {
    return (
        <div className="thank_container">
            <h2>
                <FormattedMessage
                    id = "thank.title"
                    defaultMessage = "Gracias por elegir nuestros servicios."
                />
            </h2>

            <p>
                <FormattedMessage
                    id = "thank.para"
                    defaultMessage = "Nos comunicaremos con usted lo antes posible a través de su correo electrónico."
                />
            </p>
        </div>
    )
}

export default Thank