import './style.css'

const Personnel = () => {
    return (
        <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" 
	 width="50px" height="50px" viewBox="0 0 512 512">
<g>
	<path class="st0" d="M206.896,127.063c0-6.203-5.031-11.234-11.25-11.234c-6.203,0-11.234,5.031-11.234,11.234
		c0,6.219,5.031,11.25,11.234,11.25C201.865,138.313,206.896,133.281,206.896,127.063z"/>
	<path class="st0" d="M409.677,115.828c-6.203,0-11.234,5.031-11.234,11.234c0,6.219,5.031,11.25,11.234,11.25
		c6.219,0,11.25-5.031,11.25-11.25C420.927,120.859,415.896,115.828,409.677,115.828z"/>
	<path class="st0" d="M174.24,154.406c-12.625-4.141-26.234,2.75-30.359,15.391l-44.203,94.766H71.521V95.625l-27.234,18.156
		c-5.484,3.656-9.625,8.984-11.797,15.188L3.412,245.75c-5.484,15.625-4.328,32.813,3.188,47.594l48.141,94.625
		c5.813,11.438,7.063,24.656,3.5,36.969l-20.781,71.188h108.938c0,0,19.578-117.094,27.375-149.813
		c10.688-44.797,13.969-69.875,3.641-108.219l12.234-53.344C193.787,172.125,186.88,158.531,174.24,154.406z"/>
	<path class="st0" d="M93.333,15.875v215.844l26.719-57.25c-0.188-0.016-0.359-0.094-0.547-0.125V79.766
		c18.781-3.172,33.406-18.547,35.313-37.734h108.688c-24.547,16.656-41.125,48.438-41.125,85.031s16.578,68.391,41.125,85.063h-60
		l-6,26.156h314.5V15.875H93.333z M312.537,175.703h-19.75v-23.672H260.99v-2.344v-14.875v-0.031h31.797v-11.5l-3.094-4.813H260.99
		v-3.453v-10.484v-3.438h17.516l-14.547-22.656h2.219h14.516h2.219l19.734,30.719l19.766-30.719h2.234h14.5h2.219l-14.547,22.656
		h17.516v3.438v10.484v3.453h-28.719l-3.078,4.797v11.516h31.797v0.031v14.875v2.344h-31.797V175.703z M485.833,174.344
		c-18.797,3.172-33.438,18.594-35.344,37.781H341.802c24.563-16.672,41.156-48.469,41.156-85.063s-16.594-68.375-41.156-85.031
		h108.687c1.906,19.172,16.547,34.563,35.344,37.734V174.344z"/>
</g>
</svg>
    )
}

export default Personnel;