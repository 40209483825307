import './style.css';
import { Link } from 'react-router-dom';
// eslint-disable-next-line
import {FormattedMessage} from "react-intl"


import Hero from '../../components/partials/heroSection'
import Button from '../../components/partials/button'


import banner from '../../assets/new_banner.jpg';
import wallet from '../../assets/wallet.avif';
import house from '../../assets/house.avif';
import car from '../../assets/car.avif';
import taux from '../../assets/taux_politique.jpg'
import conseiller from '../../assets/william-jackson.jpg'
import directrice from '../../assets/anne-marie.jpg'
import directeur from '../../assets/jean-beaufort.jpg'
import bloomberg from '../../assets/Bloomberg.avif';
import diversity from '../../assets/DiversityInc.avif'
import client1 from '../../assets/Clients-Love_img-2.jpeg'
import client2 from '../../assets/Clients-Love_img-3.jpeg'
import apropos from '../../assets/apropos.jpg'
import bancoEspagna from '../../assets/bancoespagna.png'
import bancoSantander from '../../assets/bancosantander.png'
import bancoBbva from '../../assets/bbva.png'
import bancoCaixa from '../../assets/caixabank.png'

import Personnel from '../../icons/personnel'
import Speed from '../../icons/speed'
import Invest from '../../icons/invest'
import Finance from '../../icons/finance'


const defaultBannerText =  "Crédito urgente, crédito rápido, préstamo urgente, préstamo 24h, préstamo sin intereses excesivos, préstamo a tipo de interés muy bajo, préstamo sin recibo, préstamo de coche, hipoteca, préstamo sin condiciones, crédito fácil sin recibo, préstamo rápido y crédito personal instantáneo online. Tantos servicios a tu disposición."
const defaultSousCard1Text = "Necesita crédito urgente o préstamo personal para gestionar sus situaciones o invertir en sus proyectos. Estamos aquí para cubrirte y darte créditos que no dañarán tu bolsillo."

const defaultSousCard2Text = "Necesitas una hipoteca, tenemos préstamos adaptados a todas las situaciones y el inmobiliario es una de ellas."
const defaultSousCard3Text = "Quieres comprar un coche nuevo y necesitas ayuda para ello. Cuéntanoslo y pronto estarás conduciendo el coche de tus sueños."
const defaultService1Text = "Fast money te acompaña en el día a día. Protegerte en caso de muerte o invalidez tras un accidente. Protégete si pierdes tu trabajo."
const defaultService2Text = "Con el préstamo de coche financiar la compra de un coche, una moto o una autocaravana. El préstamo de coche puede cubrir todo o parte del coste del vehículo."
const defaultService3Text = "Después de firmar el contrato, tienes la opción después del primer año de aumentar o reducir tus pagos mensuales iniciales hasta en un 30%."
const defaultService4Text = "Los créditos están destinados a personas físicas de todos los sectores de actividad, ya sea funcionario del sector público o privado, comerciante o trabajador, etc."
const defaultClientsCard1Text = "Busqué durante mucho tiempo la posibilidad de pedir un préstamo de 20.000 libras esterlinas para afrontar mis imprevistos. Con mis créditos vigentes en el banco preferí contactar con otros especialistas. Finalmente gracias a fastmoney pude resolver mi situación. Gracias a usted."
const defaultClientsCard2Text = "Abrir tu propio restaurante no es fácil. Después de lanzar este proyecto que estuvo cerca de mi corazón por un tiempo, excedí mi presupuesto sin haberlo terminado. Afortunadamente, Fastmoney ofreció préstamos a pesar de nuestro historial crediticio; de lo contrario, me habría despedido de mi restaurante y habría dado la bienvenida a los inversores enojados."
const defaultTauxText = "Ofrecemos préstamos en términos flexibles. Nuestras tasas de interés varían según el monto solicitado y el plazo de pago. Nuestras tarifas son relativamente bajas y oscilan entre el 2% y el 8,90%. Elegir un préstamo personal significa beneficiarse de un tipo de interés fijo que no cambia durante la duración de su préstamo. Tú eliges el importe y la duración que mejor se adapta a tu situación. Gracias al préstamo personal, puedes pedir prestado un importe que oscila entre £3.000 y £5.000.000 reembolsable en un período de 12 a 240 meses."
const defaultMembesText = "Nuestros equipos están formados por jóvenes dinámicos, inteligentes y amables cuya única misión es satisfacerte. Nuestro servicio de atención al cliente está disponible todos los días para satisfacer todas sus necesidades. No dude en llamarnos o contactarnos por correo electrónico."
const defaultConseillerText = "Un asesor de clientes dedicado le acompañará durante todo el proceso. Gestiona el depósito, sigue su expediente y le ofrece buenos consejos para una mejor gestión de su expediente crediticio."
const defaultPartenairesText1 = "El Banco Europeo de Inversiones (BEI) abrió recientemente una línea de crédito de mil millones de euros a los bancos españoles para apoyar a las pymes y las ETI. Los bancos españoles prestarán capital del BEI a pymes y ETI activas en los sectores de agricultura, turismo, industria y servicios, entre otros. Como parte de la iniciativa habilidades y trabajo, y del interés del BEI en invertir en los jóvenes, se concederán beneficios financieros adicionales a las empresas que promuevan el empleo juvenil."
const defaultPartenairesText2 = "Los primeros acuerdos celebrados con el Banco de España, el Banco Santander, el BBVA Banque y la Caixa Banque por un importe de 400 millones de euros, repartidos a partes iguales entre estos cuatro bancos, se firmaron durante un acto público en presencia del Ministro de finanzas de España. El Presidente del BEI y sus amigos de los bancos españoles firmaron cada uno en nombre de los bancos que representan."

const homeData = {
    banner: {
        heroTitle: <FormattedMessage id="home.banner.title" defaultMessage="Bienvenido a Fast Money"/>,
        heroText: <FormattedMessage id="home.banner.text" defaultMessage={defaultBannerText} />
    },
    services: [
        {
            img: wallet,
            title: <FormattedMessage id="home.souscription.card1.title" defaultMessage="Credito urgente" />,
            text: <FormattedMessage id="home.souscription.card1.text" defaultMessage={defaultSousCard1Text} />
        },
        {
            img: house,
            title: <FormattedMessage id="home.souscription.card2.title" defaultMessage="Préstamo inmobiliaro" />,
            text: <FormattedMessage id="home.souscription.card1.text" defaultMessage={defaultSousCard2Text} />
        },
        {
            img: car,
            title: <FormattedMessage id="home.souscription.card3.title" defaultMessage="Préstamo de automóvil" />,
            text: <FormattedMessage id="home.souscription.card3.text" defaultMessage={defaultSousCard3Text} />
        }
    ],
    nosServices: [
        {
            icon: <Speed/>,
            title: <FormattedMessage id="home.services.service1.title" defaultMessage="Seguro de préstamo" />,
            text: <FormattedMessage id="home.services.service1.text" defaultMessage={defaultService1Text} />
        },
        {
            icon: <Invest />,
            title: <FormattedMessage id="home.services.service2.title" defaultMessage="Seguro de préstamo" />,
            text: <FormattedMessage id="home.services.service2.text" defaultMessage={defaultService2Text} />
        },
        {
            icon: <Personnel/>,
            title: <FormattedMessage id="home.services.service3.title" defaultMessage="Seguro de préstamo" />,
            text: <FormattedMessage id="home.services.service3.text" defaultMessage={defaultService3Text} />
        },
        {
            icon: <Finance/>,
            title: <FormattedMessage id="home.services.service4.title" defaultMessage="Seguro de préstamo" />,
            text: <FormattedMessage id="home.services.service4.text" defaultMessage={defaultService4Text} />
        },
    ], nosMembres: [
        {
            img: conseiller,
            poste: <FormattedMessage id="home.membres.job2" defaultMessage="Consejero" />,
            nom: "William Jackson"
        },
        {
            img: directrice,
            poste: <FormattedMessage id="home.membres.job3" defaultMessage="Director administrativo y financiero" />,
            nom: "Anne-Marie Odell"
        },
        {
            img: directeur,
            poste: <FormattedMessage id="home.membres.job1" defaultMessage="Director" />,
            nom: "Jean Beaufort"
        }
    ],
    clients: [
        {
            img: client1,
            avis: <FormattedMessage id="home.clients.card1.text" defaultMessage={defaultClientsCard1Text} />,
            nom: "Joyce H.",
            profession: <FormattedMessage id="home.clients.card1.job" defaultMessage="Director" />
        },
        {
            img: client2,
            avis: <FormattedMessage id="home.clients.card2.text" defaultMessage={defaultClientsCard2Text} />,
            nom: "Georges P.",
            profession: <FormattedMessage id="home.clients.card2.job" defaultMessage="Cocinar" />
        }
    ],
    partenaires: [
        bancoEspagna, bancoSantander, bancoBbva, bancoCaixa
    ]
}

console.log(homeData.banner.heroTitle);


const Service = ({img, title, text}) => {
    return <div className="service">
        <div className="service_img-container">
            <img src={img} alt={title} className="service_img"/>
        </div>
        <div className="service_text">
            <h4>{title}</h4>
            <p>{text}</p>
        </div>
    </div>
}

const ServiceItem = ({icon, title, text}) => {
    return (
        <div className="nos_services-item">
            {icon}
            <article>
                <h4>{title}</h4>
                <p>{text}</p>
            </article>
        </div>
    )
}

const EquipeItem = ({img, nom, poste}) => {
    return (
        <div className="equipe">
            <img src={img} alt="equipe" />
            <div className="equipe_text">
                <h5>{nom}</h5>
                <p>{poste}</p>
            </div>
        </div>
    )
}

const ClientItem = ({img, avis, nom, profession}) => {
    return <div className="client">
        <p>{avis}</p>
        <div className="client_info">
            <img src={img} alt="client qui nous font confiance" />
            <div>
                <h5>{nom}</h5>
                <p>{profession}</p>
            </div>
        </div>
    </div>
}


// principal component

const Home = () => {
    return (
        <>
            <Hero title={homeData.banner.heroTitle} img={banner}>
                <h1>{homeData.banner.heroText}</h1>
                <Link to="/Demande-de-prêt">
                <Button>
                    <FormattedMessage id="btn1" defaultMessage="Solicitar un préstamo" />
                </Button>
                </Link>
            </Hero>
            <section className="services">
                <h2>
                    <FormattedMessage id="home.souscription.title" defaultMessage="Suscríbete al préstamo que más te convenga"/>
                </h2>
                <div className="services_list">
                    {homeData.services.map((service, ind) => <Service key={ind} {...service}/>)}
                </div>
            </section>
            <section className="section_taux-interet">
                <img src={taux} alt="taux d'intérêt" className="taux_img" />
                <div className="taux_text">
                    <h4>Solucion Urgente</h4>
                    <h3>
                        <FormattedMessage id="home.tauxInteret.title" defaultMessage="Nuestras tasas de interés"/>
                    </h3>
                    <p>
                        <FormattedMessage id="home.tauxInteret.text" defaultMessage={defaultTauxText}/>
                    </p>
                    <Link to="/Demande-de-prêt">
                    <Button>
                        <FormattedMessage id="btn1" defaultMessage="Solicitar un préstamo"/>
                    </Button>
                    </Link>
                </div>
            </section>
            <section className="nos_services">
                <h3>
                    <FormattedMessage id="home.services.title" defaultMessage="Nuestros servicios" />
                </h3>
                <div className="nos_services-list">
                    {homeData.nosServices.map((item, ind) => <ServiceItem key={ind} {...item}/>)}
                </div>
            </section>
            <section className="nos_membres">
                <h4>
                    <FormattedMessage id="home.membres.title" defaultMessage="Nuestros miembros" />
                </h4>
                <div className="nos_membres-text">
                    <p>
                        <FormattedMessage id="home.membres.text" defaultMessage={defaultMembesText} />
                    </p>
                    <Link to="/Contact">
                    <Button>
                        <FormattedMessage id="btn2" defaultMessage="Contáctenos" />
                    </Button>
                    </Link>
                </div>
                <div className="nos_membres-photos">
                    {homeData.nosMembres.map((membre, ind) => {
                        return <EquipeItem key={ind} {...membre} />
                    })}
                </div>
               
            </section>

            <section className="awards">
                <h4>
                    <FormattedMessage id="home.awards.title" defaultMessage="Hacemos todo lo posible para satisfacerle lo mejor posible" />
                </h4>
                <div className="awards_imgs">
                    <img src={bloomberg} alt="bloomberg award" />
                    <img src={diversity} alt="diversity award" />
                </div>
            </section>

            <section className="clients">
                <h4>
                    <FormattedMessage id="home.clients.title" defaultMessage="Clientes que confiaron en nosotros" />
                </h4>
                <div className="clients_items">
                    {homeData.clients.map((client, ind) => <ClientItem key={ind} {...client} />)}
                </div>
            </section>

            <section className="conseiller">
                <div className="conseiller_text">
                    <h4>
                        <FormattedMessage id="home.conseiller.title" defaultMessage="Un asesor"  />
                    </h4>
                    <p>
                        <FormattedMessage id="home.conseiller.text" defaultMessage={defaultConseillerText}  />
                    </p>
                    <Link to="mailto:contact@solucionurgente.es">
                    <Button>
                        <FormattedMessage id="btn3" defaultMessage="Contacta a un asesor" />
                    </Button>
                    </Link>
                </div>
                <div className="conseiller_img">
                    <img src={apropos} alt="conseiller pour vous" />
                </div>
            </section>

            <section className="partenaires">
                <h4>
                    <FormattedMessage id="home.partenaires.title" defaultMessage="nuestros compañeros" />
                </h4>
                <p>
                    <FormattedMessage id="home.partenaires.text1" defaultMessage={defaultPartenairesText1} />
                </p>
                <p>
                    <FormattedMessage id="home.partenaires.text2" defaultMessage={defaultPartenairesText2} />
                </p>
                <div className="partenaires_images">
                    {homeData.partenaires.map(banco => <div key={banco} className="partenaire_img-container"><img src={banco} alt="banques partenaires" /></div>)}
                </div>
            </section>
        </>
    )
}

export default Home;