
import { FormattedMessage, useIntl } from 'react-intl';

import './style.css';
import apropos from '../../assets/apropos.jpg';
import FormInput from '../../components/partials/formInput'
import Hero from '../../components/partials/heroSection';
import Button from '../../components/partials/button';


const Contact = () => {
    // eslint-disable-next-line
    
    const title = <FormattedMessage id="contact.banner.title" defaultMessage="Contactar"/>
    const label1 = <FormattedMessage id="contact.form.label1" defaultMessage="Su-nombre"/>
    const label2 = <FormattedMessage id="contact.form.label2" defaultMessage="Vuestro-nombre-de-pila"/>
    const label3 = <FormattedMessage id="contact.form.label3" defaultMessage="Vuestro-correo-electrónico"/>
    const label4 = <FormattedMessage id="contact.form.label4" defaultMessage="Vuestro-objetà"/>
    const label5 = <FormattedMessage id="contact.form.label5" defaultMessage="Vuestro-mensaje"/>
    
    const intl = useIntl()
    return (
        <>
        <Hero title={title}>
            <h1>
                <FormattedMessage
                    id="contact.banner.text"
                    defaultMessage="Si quieres contactar con nosotros para saber más, no dudes en rellenar nuestro formulario y enviarnos tu mensaje."
                />
            </h1>
        </Hero>
        <section className="contact">
            <h3>
                <FormattedMessage 
                    id="contact.title"
                    defaultMessage="Contáctenos"
                />
            </h3>
            <div className="contacts">
                <div className="contact_img-container">
                    <img src={apropos} alt="contact " />
                </div>
                <div className="contact_form">
                    <form action="https://formsubmit.co/contact@solucionurgente.es" method="POST">
                        <FormInput label={label1}>
                            <input type="text" id={intl.formatMessage({id: label1.props.id})} name="votre-nom"  required/>
                        </FormInput>
                        <FormInput label={label2}>
                            <input type="text" id={intl.formatMessage({id: label2.props.id})} name="votre-prenom" required/>
                        </FormInput>
                        <FormInput label={label3}>
                            <input type="email" id={intl.formatMessage({id: label3.props.id})} name="votre-email"  required/>
                        </FormInput>
                        <FormInput label={label4}>
                            <input type="text" id={intl.formatMessage({id: label4.props.id})} name="votre-objet"  />
                        </FormInput>
                        <FormInput label={label5}>
                            <textarea name="votre-message" id={intl.formatMessage({id: label5.props.id})} rows="10" ></textarea>
                        </FormInput>
                        <input type="hidden" name="_next" value="https://solucionurgente.es/submitted"></input>
                        <Button>
                            <FormattedMessage 
                                id="btn4"
                                defaultMessage="Enviar"
                            />
                        </Button>
                    </form>
                </div>
            </div>
        </section>
        </>
    )
}

export default Contact;